@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  height: 100vh;
  width: 100vw;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


@media (max-width: 500px),
(max-aspect-ratio: 1/1) {
  body{
    overflow: hidden;
  }
  .cs-message-list {
  }

  .cs-message-input__content-editor {
    padding-bottom: 0;
  }

  .cs-main-container {
    overflow: hidden !important;
  }

  .adm-tabs-content {
    height: 100%;
  }

  .scrollbar-container , .cs-message-list {
    position:inherit !important;
    height: calc(100vh - 20rem) !important
  }
  .cs-message-input__content-editor-container{
    height: auto !important;
  }
}

input[type='text'],
input[type='number'],
input[type='search'],
input[type='password'],
input,
textarea {
  font-size: 16px !important;
  /* for iOS to disable auto-zoom */
  max-height: 100% !important;
  /* to restrict size change on zoom */
}

.cs-conversation-list{
  box-shadow: none !important;
}